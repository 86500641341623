<template>
  <div>
    <ul class="popup" id="right-click" @mouseleave="hiddenPopup" @click="hiddenPopup">
      <div class="text-center p-1"><code>{{ this.shipperSelected.code }}</code></div>
      <li @click="vaoCaHetCa()"><b-icon icon="x-circle"/> <b>Hết ca</b> </li>
      <li @click="smsModal('one_shipper')" v-b-modal.smsmodal>
        <b-icon icon="chat-dots"/> SMS
      </li>
    </ul>

    <b-table @row-contextmenu="handleContextMenuEvent" striped bordered hover small
             :items="shippersfiltered"
             :fields="fields"
             sort-by="order_cnt_today_going"
             :sort-desc="false">
      <template #cell(ban_don)="data">
        <div class="text-center" style="width:35px">
          <b-button v-if="showBtnPHANCONG == true" size="sm" variant="default" @click="phancong(data.item)">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" fill="#039be5" r="12"/>
              <path
                  d="m5.491 11.74 11.57-4.461c.537-.194 1.006.131.832.943l.001-.001-1.97 9.281c-.146.658-.537.818-1.084.508l-3-2.211-1.447 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953z"
                  fill="#fff"/>
            </svg>
          </b-button>
        </div>
      </template>
      <template #cell(code)="data">
        <div>{{ data.item.code }}</div>
      </template>
      <template #cell(order_cnt_today_going)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.order_cnt_today_going }}/{{ data.item.order_cnt_today }}</span>
        </div>
      </template>
      <template #cell(order_sum_fee)="data">
        <div><code>{{ data.item.order_sum_fee }}</code></div>
      </template>
      <template #cell(order_cnt_urgent)="data">
        <div>{{ data.item.order_cnt_urgent }}</div>
      </template>
      <template #cell(location)="data">
        <div>{{ data.item.location }}</div>
      </template>
      <template #cell(state)="data">
        <b-badge v-if="data.item.state=='Chờ đơn' || data.item.state=='Vào ca'" variant="danger">{{ data.item.state }}</b-badge>
        <b-badge v-else-if="data.item.state=='Đang đi'" variant="primary">{{ data.item.state }}</b-badge>
        <b-badge v-else-if="data.item.state=='Xong đơn'" variant="success">{{ data.item.state }}</b-badge>
        <b-badge v-else variant="info">{{ data.item.state }}</b-badge>
      </template>
      <template #cell(state_from_now)="data">
        <span id="state_from_now" v-if="data.item.shift_updated_at != null">{{ moment(data.item.shift_updated_at).fromNow() }}</span>
      </template>
      <template #cell(state_updated_at)="data">
        <code >{{ data.item.state_updated_at }}</code>
      </template>
      <template #cell(thoigian_xongdon_gannhat)="data">
        <code v-if="data.item.order_cnt_today_going == 0 && data.item.thoigian_xongdon_gannhat != null && data.item.thoigian_xongdon_gannhat != ''">{{ moment(data.item.thoigian_xongdon_gannhat).fromNow() }}</code>
      </template>
      <template #cell(address_from)="data">
        <div>{{ data.item.address_from }}</div>
      </template><template #cell(address_to)="data">
        <div>{{ data.item.address_to }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment-timezone'
moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale('vi')
// moment.fn.fromNow = function (a) {
//   return moment().diff(this, 'minutes');
// }
export default {
  name: "TableShipper",
  props: ['showBtnPHANCONG', 'shippersfiltered'],
  data() {
    return {
      moment,
      fields: [
        {
          key: 'ban_don',
          label: '',
          thStyle: {width: '42px'}
        },
        {
          key: 'code',
          label: 'Code',
          sortable: true,
          thStyle: {width: '130px'}
        },
        {
          key: 'order_cnt_today_going',
          label: 'SL',
          sortable: true,
          thStyle: {width: '35px'}
        },
        {
          key: 'order_sum_fee',
          label: 'Tiền',
          sortable: true,
          thStyle: {width: '40px'}
        },
        {
          key: 'state',
          label: 'TThái',
          sortable: true,
          thStyle: {width: '40px'}
        },
        {
          key: 'state_from_now',
          label: 'TThái chờ',
          sortable: true,
          thStyle: {width: '40px'}
        },
        {
          key: 'state_updated_at',
          label: 'Nhận vị trí',
          sortable: true,
          thStyle: {width: '40px'}
        },
        {
          key: 'thoigian_xongdon_gannhat',
          label: 'Xong từ',
          sortable: true,
          thStyle: {width: '40px'}
        },
        {
          key: 'address_from',
          label: 'Từ A',
          sortable: true,
        },
        {
          key: 'address_to',
          label: 'Về B',
          sortable: true,
        },
      ],
      shipperSelected: '',
    }
  },
  methods: {
    phancong(shipper) {
      this.$emit('phancong', shipper);
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.shipperSelected = item;
      document.getElementById('right-click').style.visibility = 'visible'
      document.getElementById('right-click').style.top = event.clientY + 'px';
      document.getElementById('right-click').style.left = event.clientX + 'px';
    },
    hiddenPopup() {
      document.getElementById('right-click').style.visibility = 'hidden';
    },
    vaoCaHetCa() {
      this.$socket.emit('SOCKET_V2_SERVER_SHIPPER_VAOCAHETCA', this.shipperSelected);
    },
    smsModal(type_sms) {
      this.$parent.smsModal(type_sms, this.shipperSelected);
    },
  }
}
</script>





<style lang="scss" scoped>
code {
  font-size: inherit;
}
.popup {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 10px;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  visibility: hidden;
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px 5px 3px;
  white-space: nowrap;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}

#state_from_now {
  font-size: 12px;
}
</style>
